<template>
  <div class="text-center">
    <v-img
      :src="thumbnailUrl"
      cover
      class="category-cover mb-2"
      width="80"
      height="80" />
    <div class="category-name">
      {{ name }}
    </div>
  </div>
</template>

<script>
  import CategoryItemMixin from '@/components/categories/category-item/CategoryItem.mixin.vue';
  export default {
    mixins: [CategoryItemMixin],
  };
</script>

<style lang="css" scoped>
  .category-cover {
    width: 96px;
    height: 96px;
    border-radius: 16px;
  }
  .category-name {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: var(--v-brandGrey1-base);
    width: 80px;
  }
  .category-item {
    width: 80px;
    margin: 0 auto;
  }
</style>
