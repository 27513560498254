<script>
  import getThumbnail from '@/utils/getThumbnail.js';
  export default {
    name: 'category-item',
    data() {
      return {
        thumbnailUrl: null,
      };
    },
    props: {
      cover: {
        type: String,
        required: true,
      },
      name: {
        type: String,
        required: true,
      },
    },
    async created() {
      this.setThumbnailUrl();
    },
    methods: {
      async setThumbnailUrl() {
        try {
          this.thumbnailUrl = await getThumbnail({
            url: this.cover,
            folder: 'categoriesLogos',
          });
        } catch {
          // fallback to the original image
          this.thumbnailUrl = this.cover;
        }
      },
    },
  };
</script>
